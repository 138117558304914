// export const IP = "https://unuhdevapi.kdev.co.in/api";
// export const IP = "http://192.168.0.112:3001/api";
// export const dev = false;
export const dev = process.env.NODE_ENV === "development";
export const PG_URL = "https://jm.wipayfinancial.com/plugins/payments/request";
export const IP = dev
  ? "http://localhost:3001/api"
  : "https://unuhdevapi.kdev.co.in/api";
export const POST = "post";
export const GET = "get";
export const PATCH = "patch";
export const DELETE = "delete";
export const ROLE_LIST = {
  SUPER_ADMIN: "SUPER_ADMIN",
  SCHOOL_ADMIN: "SCHOOL_ADMIN",
  PARENT_ADMIN: "PARENT_ADMIN",
  STUDENT: "STUDENT",
  FACILITATOR: "INSTRUCTOR",
  CONTENT_UPLOADER: "CONTENT_UPLOADER",
};
// const ip = "http://localhost:3001/api"

export const threshold = 500;
