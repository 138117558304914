import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  dFlex,
  flexSpaceBetween,
  itemsCenter,
} from "../../../../common/commonStyles";
import { SvgVector } from "../../../Builder/icons";

export default function ModuleMatchPairsCard({ value, index }) {
  // const [options, setOptions] = useState([
  //   { label: "Lorem ipsum ist" },
  //   { label: "Lorem ipsum ist" },
  //   { label: "Lorem ipsum ist" },
  //   { label: "Lorem ipsum ist" },
  // ]);
  const tempArr = [];

  const getLabel2 = (idx) => {
    for (let i = 0; i < value?.data?.correctOptions?.length; i++) {
      if (value?.data?.correctOptions[i]?.match == idx)
        return value?.data?.correctOptions[i]?.text;
    }
  };
  value.data.options.map((_, idx) => {
    tempArr.push({
      label1: _,
      label2: getLabel2(idx + 1),
    });
  });
  const [options, setOptions] = useState(tempArr);
  return (
    <Box
      sx={{
        my: 3,
        px: 3,
        borderRadius: "15px",
      }}
    >
      <Box sx={{ mb: 1, ...flexSpaceBetween, gap: 8 }}>
        <Typography
          variant="medium"
          sx={{
            flex: "80%",
            color: (theme) => theme.palette.text.main,
          }}
        >
          {index + 1}. {value.question}
        </Typography>
      </Box>
      <Box sx={{ ...dFlex }}>
        <Box
          sx={{
            ...dFlex,
            flexDirection: "column",
            mt: 2,
            flex: "80%",
            p: "9px 20px",
          }}
        >
          {options?.map((e, i) => (
            <Box sx={{ ...dFlex, gap: "10px" }}>
              <Box sx={{ position: "relative", overflow: "hidden" }}>
                <Box
                  sx={{
                    width: { xs: "100px", md: "170px" },
                    height: "50px",
                    mb: 1,
                    background: (theme) => theme.palette.primary.main,
                    // borderRadius: "8px 0px 0px 8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <SvgVector />{" "}
                  <Typography sx={{ color: "#fff" }}>{e.label1}</Typography>
                </Box>
                <Box
                  sx={{
                    width: { xs: "100px", md: "170px" },
                    clipPath: "ellipse(5% 12% at 100% 50%)",
                    height: "50px",
                    background: "#fff",
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                  }}
                ></Box>
              </Box>
              <Box sx={{ position: "relative", overflow: "hidden" }}>
                <Box
                  sx={{
                    width: { xs: "100px", md: "170px" },
                    minWidth: { xs: "100px", md: "220px" },
                    height: "50px",
                    background: (theme) => theme.palette.primary.main,
                    position: "absolute",
                    clipPath: "ellipse(5% 12% at 5% 50%)",
                    // top: "0px",
                    // left: "0px",
                  }}
                ></Box>
                <Box
                  sx={{
                    width: { xs: "100px", md: "170px" },
                    minWidth: { xs: "100px", md: "220px" },
                    clipPath: "inset(0 0 0 5%)",
                    height: "50px",
                    background: (theme) => theme.palette.primary.main,
                    // borderRadius: "0px 8px 8px 0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "10px",
                  }}
                >
                  {/* <DragIcons /> */}
                  <Typography sx={{ color: "#fff", paddingLeft: "10px" }}>
                    {e.label2} developrt lsjkdfsd jkl fjlksd lfsjlkd klsdlk
                    jfjsdj
                  </Typography>
                  <SvgVector />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            flex: "10%",
            alignSelf: "flex-end",
          }}
        >
          <Typography
            variant="medium"
            sx={{
              pl: 1,
              color: (theme) => theme.palette.text.main,
            }}
          >
            {value?.studentAttempted ? value?.studentAttempted : "0"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
